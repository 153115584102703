<template>
	<div>
		<SlideShow :bannerList="bannerList"></SlideShow>
		<div class="searchBar">
			<Search type="1393" :keys="getListParams.key" @searchKeyFun="searchKeyFun"></Search>
		</div>
		<template>
			<div class="main_Div contBox">
				<MaxClassifyMenu :keys="getListParams.key" @selectParams="selectParams" @changeMenu="changeMenu"
					@changeData="changeData"></MaxClassifyMenu>
				<div class="ListBox">
					<MaxList ref="list" :maxList="maxListObj" :loading="loading" :listStatus="listStatus"
						@nextPage="nextPage"></MaxList>
				</div>
			</div>
		</template>
	</div>
</template>
<script>
	import SlideShow from "@/components/SlideShow";
	import Search from "@/components/Search";
	import MaxList from "@/components/MaxList";
	import MaxClassifyMenu from "@/components/MaxClassifyMenu";
	import {
		getBanner
	} from "../../Api";
	import {
		mapGetters
	} from "vuex";

	export default {
		name: "MaxIndex",
		data() {
			return {
				bannerList: [],
				loading: true,
				listStatus: {
					isData: true, // 判断是否有下一页
					isNull: false // 判断是否为空
				},
				getListParams: {
					px: "", //排序
					key: "", // 关键字
					page: 1, // 分页
					free: "", //免费
					id: null, // 分类id
					style: "", //风格
					renderer: "", //渲染器
					size: "", //大小
					reCom: "",
				},
			};
		},
		components: {
			SlideShow,
			Search,
			MaxList,
			MaxClassifyMenu,
		},
		created() {
			const params = {
				position: 1035,
				cate: 1982,
			};
			getBanner(params).then((res) => {
				this.bannerList = res.data;
			});
		},
		methods: {
			//选择其他属性
			selectParams(data) {
				this.$refs.list && this.$refs.list.clear();
				this.getListParams[data.type] = data.value;
				this.getListParams.page = 1;
			},
			//修改请求数据
			changeData(data) {
				this.$refs.list && this.$refs.list.clear();
				this.getListParams.page = 1;
				this.getListParams = data;
			},
			//选择菜单
			changeMenu(data) {
				this.$refs.list && this.$refs.list.clear();
				this.getListParams.page = 1;
				this.getListParams.id = data;
			},
			// 下一页
			nextPage() {
				console.log('下一页');
				this.getListParams.page += 1;
			},
			//设置分页
			setPage(val) {
				this.getListParams.page = val;
				//回到顶部
				cancelAnimationFrame(this.timer);
				const self = this;
				self.timer = requestAnimationFrame(function fn() {
					const oTop =
						document.body.scrollTop || document.documentElement.scrollTop;
					if (oTop > 0) {
						document.body.scrollTop = document.documentElement.scrollTop =
							oTop - 100;
						self.timer = requestAnimationFrame(fn);
					} else {
						cancelAnimationFrame(self.timer);
					}
				});
			},
			//  搜索
			searchKeyFun(keys) {
				this.$refs.list && this.$refs.list.clear();
				this.getListParams.page = 1;
				this.getListParams.key = keys;
			},
		},
		computed: {
			...mapGetters(["maxListObj"]),
		},
		watch: {
			getListParams: {
				handler: function() {
					this.loading = true;
					(this.listStatus.isData = true), (this.listStatus.isNull = false);
					let params = this.$dealObjectValue(this.getListParams);
					this.$store
						.dispatch("app/getMaxList", params)
						.then((res) => {
							this.loading = false;
							const {
								data
							} = res;
							if (data.total == 0) {
								this.$refs.list && this.$refs.list.clear();
								this.listStatus.isNull = true;
							} else if (data.current_page == data.last_page) {
								this.listStatus.isData = false;
							}
						})
						.catch((error) => {
							console.log(error);
							this.loading = false;
						});
				},
				deep: true,
			},
		},
	};
</script>
<style>
	.pageBox {
		width: 100%;
		text-align: center;
	}

	.searchBar {
		position: absolute;
		width: 100%;
		min-width: 1400px;
		top: 2rem;
		z-index: 8;
	}

	.contBox {
		position: relative;
		top: -0.66rem;
		z-index: 8;
	}
</style>
