<!--分类筛选组件-->
<template>
  <div>
    <div class="classifyCont">
      <div class="classifyBox">
        <div class="classifyItem mt25">
          <div class="classifyItemName mr40">一级分类</div>
          <div class="classifyChild">
            <div
              class="classifyChildItem"
              :class="{ active: activeIndex == -1 }"
              @click="all(1)"
            >
              全部
            </div>
            <div
              class="classifyChildItem"
              :class="{ active: activeIndex == item.parameter }"
              v-for="(item, index) in menuList"
              :key="index"
              @click="getChildMenu(item.parameter, index)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="classifyItem mt10" v-show="menuChildList.length > 0">
          <div class="classifyItemName mr40 mt20">二级分类</div>
          <div class="classifyChild">
            <div
              class="classifyChildItem mt20"
              :class="{ active: activeChildIndex == -1 }"
              @click="all(2)"
            >
              全部
            </div>
            <div
              class="classifyChildItem mt20"
              :class="{ active: activeChildIndex == item.parameter }"
              v-for="(item, index) in menuChildList"
              :key="index"
              @click="getChildMenu3(item.parameter, index)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="classifyItem mt10" v-show="menuChildList3.length > 0">
          <div class="classifyItemName mr40 mt20">三级分类</div>
          <div class="classifyChild">
            <div
              class="classifyChildItem mt20"
              :class="{ active: activeChildIndex3 == -1 }"
              @click="all(3)"
            >
              全部
            </div>
            <div
              class="classifyChildItem mt20"
              :class="{ active: activeChildIndex3 == item.parameter }"
              v-for="(item, index) in menuChildList3"
              :key="index"
              @click="selectMenu(item.parameter, index)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="classifyItem mt10" v-show="suAttrData != null">
          <div class="classifyItemName mr40 mt20">风格筛选</div>
          <div class="classifyChild">
            <div
              class="classifyChildItem mt20"
              :class="{ active: suStyle == '' }"
              @click="selectStyle('')"
            >
              全部
            </div>
            <div
              class="classifyChildItem mt20"
              :class="{ active: suStyle == item }"
              v-for="(item, index) in suAttrData.style"
              :key="index"
              @click="selectStyle(item, index)"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ContBox">
			<div style="height: 0.36rem;"></div>
      <div class="content" :class="{ isFixe: !tagBarFixed }">
        <div class="dfc fl mr60">
          <div class="df_Yc tagName">
            <img class="tagIcon" src="@/assets/image/icon/px-icon.png" alt="" />
            <div class="tagTile">排序</div>
          </div>
          <div class="df_Yc">
            <div
              class="tag"
              v-for="(item, index) in pxOption"
              :key="index"
              :class="{ active: px == item.value }"
              @click="selectPx(item.value)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="dfc fl mr60">
          <div class="df_Yc tagName">
            <img
              class="tagIcon"
              src="@/assets/image/icon/screen-icon.png"
              alt=""
            />
            <div class="tagTile">筛选</div>
          </div>
          <div class="df_Yc">
            <div
              class="tag"
              v-for="(item, index) in freeOption"
              :key="index"
              :class="{ active: free == item.value }"
              @click="selectFree(item.value)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="dfc fl mr60">
          <div class="df_Yc tagName">
            <img
              class="tagIcon"
              src="@/assets/image/icon/zan-icon.png"
              alt=""
            />
            <div class="tagTile">推荐</div>
          </div>
          <div class="df_Yc">
            <div
              class="tag"
              v-for="(item, index) in ReComOption"
              :key="index"
              :class="{ active: reCom == item.value }"
              @click="selectReCom(item.value)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="dfc fr">
          <div class="df_Yc tagName" style="margin-right: 0.2rem">
            <div class="tagTile">大小</div>
          </div>
          <div>
            <el-select
              v-model="suSize"
              placeholder="请选择"
              style="width: 1.25rem"
              @change="selectSize"
            >
              <el-option label="全部" value=""> </el-option>
              <el-option
                v-for="(item, index) in suAttrData.size"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="dfc fr mr20">
          <p class="f14 f0404 mr10">渲染器</p>
          <el-select
            v-model="renderCom"
            placeholder="请选择"
            style="width: 1.25rem"
            @change="selectRenderCom"
          >
            <el-option label="全部" value=""> </el-option>
            <el-option
              v-for="(item, index) in suAttrData.Renderer"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="content" :class="{ isFixe1: tagBarFixed }">
        <div class="fixedContent">
          <div class="fl mr40">
            <el-select
              v-model="px"
              placeholder="请选择"
              style="width: 1.25rem"
              @change="selectPx"
            >
              <el-option
                v-for="item in pxOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
          </div>
          <div class="df_Yc fl mr40">
            <p class="f14 f0404 mr10">筛选</p>
            <el-select
              v-model="free"
              placeholder="请选择"
              style="width: 1.25rem"
              @change="selectFree"
            >
              <el-option
                v-for="item in freeOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="df_Yc fl mr40">
            <p class="f14 f0404 mr10">渲染器</p>
            <el-select
              v-model="renderCom"
              placeholder="请选择"
              style="width: 1.25rem"
              @change="selectRenderCom"
            >
              <el-option label="全部" value=""> </el-option>
              <el-option
                v-for="(item, index) in suAttrData.Renderer"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="df_Yc fl mr40">
            <p class="f14 f0404 mr10">大小</p>
            <el-select
              v-model="suSize"
              placeholder="请选择"
              style="width: 1.25rem"
              @change="selectSize"
            >
              <el-option label="全部" value=""> </el-option>
              <el-option
                v-for="(item, index) in suAttrData.size"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="searchF fr">
            <el-input
              placeholder="请输入关键词"
              v-model="keysTxt"
              @keyup.enter.native="searchKey"
              clearable
              @clear="searchKey"
            ></el-input>
            <i class="iconfont f20 c_99 c_p" @click="searchKey">&#xe67d;</i>
          </div>
          <div class="df_Yc fr mr40">
            <p class="f14 f0404 mr10">风格</p>
            <el-select
              v-model="suStyle"
              placeholder="请选择"
              style="width: 1.25rem"
              @change="selectSize"
            >
              <el-option
                v-for="(item, index) in suAttrData.style"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="df_Yc fr mr40">
            <p class="f14 f0404 mr10">分类</p>
            <el-cascader
              ref="refHandle"
              :props="props"
              :show-all-levels="false"
              v-model="cloudList"
              @change="handleChange"
            ></el-cascader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getMaxAttributes, getMenu} from "../Api";
export default {
  name: "MaxClassifyMenu",
  props: ["keys"],
  data() {
    return {
      menuList: [], // 一级菜单
      menuChildList: [], // 二级菜单
      menuChildList3: [], // 三级菜单
      activeIndex: -1, // 一级菜单选择的索引
      activeChildIndex: -1, // 二级菜单选择的索引
      activeChildIndex3: -1, // 三级菜单选择的索引
      cloud: null, // 选中的菜单id
      cloudList: [], // 吸顶菜单所需
      tagBarFixed: false, // 是否显示吸顶菜单，
      suAttrData: {
        style: [],
        size: [],
        version: [],
        Renderer: [],
      }, // su属性
      reCom: "",
      px: 1, //排序
      free: 0, //是否免费
      suStyle: "",
      renderCom: "",
      suSize: "", //SU大小
      keysTxt: "", // 搜索关键字
      //排序选项
      pxOption: [
        // {
        //   value: 0,
        //   label: "综合",
        // },
        {
          value: 1,
          label: "最新",
        },
        {
          value: 2,
          label: "热门",
        },
      ],
      //  筛选选项
      freeOption: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "免费",
        },
        {
          value: 2,
          label: "付费",
        },
        {
          value: -1,
          label: "VIP",
        },
      ],
      //  推荐选项
      ReComOption: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 76,
          label: "原创",
        },
        {
          value: 79,
          label: "整理",
        },
        {
          value: 77,
          label: "临摹",
        },
      ],
      props: {
        checkStrictly: true,
        lazy: true,
        lazyLoad: this.lazyLoad,
      },
      getListParams: {
        px: 1, //排序
        key: "", // 关键字
        page: 1, // 分页
        free: "", //免费
        id: 1393, // 分类id
        style: "", //风格
        renderer: "", //渲染器
        size: "", //大小
        reCom: "",
      },
    };
  },
  created() {
    this.keysCont = this.keys;
    if (this.$route.query.search) {
      this.getListParams.key = this.$route.query.search;
    }
    if (this.$route.query.sort) {
      this.px = parseInt(this.$route.query.sort);
      this.getListParams.px = this.px;
    }
    if (this.$route.query.free) {
      this.free = parseInt(this.$route.query.free);
      this.getListParams.free = this.free;
    }
    if (this.$route.query.renderCom) {
      this.renderCom = this.$route.query.renderCom;
      this.getListParams.renderer = this.renderCom;
    }
    if (this.$route.query.suStyle) {
      this.suStyle = this.$route.query.suStyle;
      this.getListParams.style = this.suStyle;
    }
    if (this.$route.query.suSize) {
      this.suSize = this.$route.query.suSize;
      this.getListParams.size = this.suSize;
    }
    if (this.$route.query.reCom) {
      this.reCom = this.$route.query.reCom;
      this.getListParams.reCom = this.reCom;
    }
    //获取路由参数
    if (
      this.$route.query.child1 &&
      this.$route.query.child2 &&
      this.$route.query.child3
    ) {
      this.activeIndex = this.$route.query.child1;
      this.activeChildIndex = this.$route.query.child2;
      this.activeChildIndex3 = this.$route.query.child3;
      this.cloudList = [
        parseInt(this.$route.query.child1),
        parseInt(this.$route.query.child2),
        parseInt(this.$route.query.child3),
      ];
      this.initData();
      this.getMenu(this.activeIndex).then((res) => {
        this.menuChildList = res.data.data;
      });
      this.getMenu(this.activeChildIndex).then((res) => {
        this.menuChildList3 = res.data.data;
      });
      // this.cloud = this.activeChildIndex3;
			this.getListParams.id = this.activeChildIndex3;
      this.$emit("changeData", this.getListParams);
    } else if (this.$route.query.child1 && this.$route.query.child2) {
      this.activeIndex = this.$route.query.child1;
      this.activeChildIndex = this.$route.query.child2;
      this.cloudList = [
        parseInt(this.$route.query.child1),
        parseInt(this.$route.query.child2),
      ];
      this.initData();
      this.getMenu(this.activeIndex).then((res) => {
        this.menuChildList = res.data.data;
      });
      this.getMenu(this.activeChildIndex).then((res) => {
        this.menuChildList3 = res.data.data;
      });
      // this.cloud = this.activeChildIndex;
			this.getListParams.id = this.activeChildIndex;
      this.$emit("changeData", this.getListParams);
    } else if (this.$route.query.child1) {
      this.activeIndex = this.$route.query.child1;
      this.cloudList = [parseInt(this.activeIndex)];
      this.initData();
      this.getChildMenu(this.activeIndex);
      // this.cloud = this.activeIndex;
			this.getListParams.id = this.activeIndex;
      this.$emit("changeData", this.getListParams);
    } else {
      this.initData();
      this.cloud = 1393;
			this.$emit("changeData", this.getListParams);
    }
  },
  methods: {
    all(type) {
      if (type == 1) {
        this.activeIndex = -1;
        //将参数设置到地址栏
        const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
        delete query.child1; //改变参数
        delete query.child2;
        delete query.child3;
        this.$router.push({ path: this.$route.path, query });
        this.menuChildList = [];
        this.menuChildList3 = [];
        this.cloud = 1393;
      } else if (type == 2) {
        this.activeChildIndex = -1;
        //将参数设置到地址栏
        const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
        delete query.child2; //改变参数
        delete query.child3;
        this.$router.push({ path: this.$route.path, query });
        this.menuChildList3 = [];
        this.cloud = this.activeIndex;
      } else {
        this.activeChildIndex3 = -1;
        //将参数设置到地址栏
        const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
        delete query.child3; //改变参数
        this.$router.push({ path: this.$route.path, query });
        this.cloud = this.activeChildIndex;
      }
    },
    initData() {
      //获取一级菜单和su风格、大小、渲染器，
      Promise.all([this.getMenu(1393), this.getAttrFun()]).then((res) => {
        const menuRes = res[0];
        const attrRes = res[1];
        this.menuList = menuRes.data.data;
        this.suAttrData = attrRes.data;
      });
    },
    //获取菜单
    getMenu(id) {
      return new Promise((resolve) => {
        getMenu(id).then((res) => {
          resolve(res);
        });
      });
    },
    //  获取max属性
    getAttrFun() {
      return new Promise((resolve) => {
        getMaxAttributes().then((res) => {
          resolve(res);
        });
      });
    },
    // 点击一级菜单获取二级菜单
    getChildMenu(id) {
      this.activeIndex = id;
      this.activeChildIndex = -1;
      this.activeChildIndex3 = -1;
      this.menuChildList3 = [];
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.child1 = this.activeIndex; //改变参数
      delete query.child2;
      delete query.child3;
      this.$router.push({ path: this.$route.path, query });
      this.getMenu(id).then((res) => {
        this.menuChildList = res.data.data;
        this.cloud = id;
        this.cloudList = [
          parseInt(this.activeIndex),
        ];
				this.resetMenuList()
      });
    },
    // 点击二级菜单获取三级菜单
    getChildMenu3(id) {
      if (this.activeChildIndex != id) this.activeChildIndex3 = -1;
      this.activeChildIndex = id;
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.child2 = this.activeChildIndex; //改变参数
      this.$router.push({ path: this.$route.path, query });
      this.getMenu(id).then((res) => {
        this.cloudList = [
          parseInt(this.activeIndex),
          parseInt(this.activeChildIndex),
        ];
        this.menuChildList3 = res.data.data;
        this.cloud = id;
				this.resetMenuList()
      });
    },
    //点击三级菜单
    selectMenu(id) {
      this.activeChildIndex3 = id;
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.child3 = this.activeChildIndex3; //改变参数
      this.$router.push({ path: this.$route.path, query });
      this.cloudList = [
        parseInt(this.activeIndex),
        parseInt(this.activeChildIndex),
        id,
      ];
      this.cloud = id;
			this.resetMenuList()
    },
    //选择推荐
    selectReCom(reCom) {
      this.reCom = reCom;
      const data = { type: "reCom", value: reCom };
      this.$emit("selectParams", data);
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.reCom = reCom; //改变参数
      this.$router.push({ path: this.$route.path, query });
    },
    //选择排序
    selectPx(sort) {
      this.px = sort;
      const data = { type: "px", value: sort };
      this.$emit("selectParams", data);
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.sort = sort; //改变参数
      this.$router.push({ path: this.$route.path, query });
    },
    //选择筛选
    selectFree(free) {
      this.free = free;
      const data = { type: "free", value: free };
      this.$emit("selectParams", data);
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.free = free; //改变参数
      this.$router.push({ path: this.$route.path, query });
    },
    //选择渲染器
    selectRenderCom(val) {
      this.renderCom = val;
      const data = { type: "renderer", value: val };
      this.$emit("selectParams", data);
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.renderCom = val; //改变参数
      this.$router.push({ path: this.$route.path, query });
    },
    //选择风格
    selectStyle(val) {
      this.suStyle = val;
      const data = { type: "style", value: val };
      this.$emit("selectParams", data);
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.suStyle = val; //改变参数
      this.$router.push({ path: this.$route.path, query });
    },
    //选择大小
    selectSize() {
      const data = { type: "size", value: this.suSize };
      this.$emit("selectParams", data);
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
      query.suSize = this.suSize; //改变参数
      this.$router.push({ path: this.$route.path, query });
    },
    //搜索
    searchKey() {
      const data = { type: "key", value: this.keysTxt };
      this.$emit("selectParams", data);
    },
    //吸顶菜单联动器方法
    handleChange() {
      if (this.cloudList.length == 0) {
        if (this.$route.query.child1) {
          this.cloudList = [parseInt(this.activeIndex)];
        }
        if (this.$route.query.child1 && this.$route.query.child2) {
          this.cloudList = [
            parseInt(this.activeIndex),
            parseInt(this.activeChildIndex),
          ];
        }
        if (
          this.$route.query.child1 &&
          this.$route.query.child2 &&
          this.$route.query.child3
        ) {
          this.cloudList = [
            parseInt(this.activeIndex),
            parseInt(this.activeChildIndex),
            parseInt(this.activeChildIndex3),
          ];
        }
      }
      let id = null;
      if (this.cloudList.length == 1) {
        // 选定一级菜单 获取二级菜单
        id = this.cloudList[0];
        this.menuList.filter((obj) => {
          if (obj.parameter === id) {
            this.getMenu(id).then((res) => {
              this.activeChildIndex = -1;
              this.activeIndex = id;
              this.menuChildList = res.data.data;
              this.menuChildList3 = [];
              //将参数设置到地址栏
              const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
              query.child1 = this.activeIndex; //改变参数
							this.cloud = id;
              delete query.child2;
              delete query.child3;
              this.$router.push({ path: this.$route.path, query });
            });
          }
        });
      } else if (this.cloudList.length == 2) {
        // 选定二级菜单
        this.menuList.filter((obj) => {
          if (obj.parameter === this.cloudList[0]) {
            this.getMenu(this.cloudList[0]).then((res) => {
              this.menuChildList = res.data.data;
              this.activeIndex = this.cloudList[0];
              this.menuChildList.filter((obj2) => {
                id = this.cloudList[1];
                if (obj2.parameter === id) {
                  this.getMenu(id).then((res) => {
                    this.menuChildList3 = res.data.data;
                    this.activeChildIndex = this.cloudList[1];
                    this.activeChildIndex3 = -1;
                    //将参数设置到地址栏
                    const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
										query.child1 = this.activeIndex; //改变参数
                    query.child2 = this.activeChildIndex; //改变参数
										this.cloud = id;
                    delete query.child3;
                    this.$router.push({ path: this.$route.path, query });
                  });
                }
              });
            });
          }
        });
      } else {
        // 选定三级菜单
        this.menuList.filter((obj) => {
          if (obj.parameter === this.cloudList[0]) {
            this.getMenu(this.cloudList[0]).then((res) => {
              this.menuChildList = res.data.data;
              this.activeIndex = this.cloudList[0];
              this.menuChildList.filter((obj2) => {
                if (obj2.parameter === this.cloudList[1]) {
                  this.getMenu(this.cloudList[1]).then((res) => {
                    this.menuChildList3 = res.data.data;
                    this.activeChildIndex = this.cloudList[1];
                    this.activeChildIndex3 = -1;
                    this.menuChildList3.filter((obj) => {
                      id = this.cloudList[2];
                      if (obj.parameter === id) {
                        this.activeChildIndex3 = this.cloudList[2];
                        //将参数设置到地址栏
                        const query = JSON.parse(
                          JSON.stringify(this.$route.query)
                        ); // 获取路由参数信息
												query.child1 = this.activeIndex; //改变参数
												query.child2 = this.activeChildIndex; //改变参数
                        query.child3 = this.activeChildIndex3; //改变参数
												this.cloud = id;
                        this.$router.push({ path: this.$route.path, query });
                      }
                    });
                  });
                }
              });
            });
          }
        });
      }
      this.resetSelectMenu();
      this.cloud = id;
      // 监听值发生变化就关闭它
      if (this.$refs.refHandle)
        return (this.$refs.refHandle.dropDownVisible = false);
    },
    //重置吸顶菜单选择器
    resetSelectMenu() {
      this.$refs.refHandle.$refs.panel.checkedValue = [];
      this.$refs.refHandle.$refs.panel.activePath = [];
      this.$refs.refHandle.$refs.panel.syncActivePath();
    },
    //刷新吸顶菜单列表
    resetMenuList() {
      let cs = this.$refs["refHandle"];
      cs.panel.activePath = [];
      cs.panel.loadCount = 0;
      cs.panel.lazyLoad();
    },
    //动态加载吸顶菜单选择器
    lazyLoad(node, resolve) {
      const { level } = node;
      const id = level == 0 ? 1393 : node.value;
      this.getMenu(id).then((res) => {
        const nodes = res.data.data.map((item) => ({
          value: item.parameter,
          label: item.name,
          leaf: node.level >= 2,
        }));
        resolve(nodes);
      });
    },
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const offsetTop = document.querySelector(".ContBox").offsetTop * 3.5;
      if (scrollTop > offsetTop) {
        this.tagBarFixed = true;
      } else {
        this.tagBarFixed = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    cloud(val) {
      if (val != null) this.$emit("changeMenu", val); // 这里出现一个bug cloud会为null （记到解决）
    },
    keys(val) {
      this.keysTxt = val;
    },
  },
};
</script>

<style scoped>
::v-deep .el-input--suffix .el-input__inner {
  padding-right: 15px;
}
.searchF >>> .el-input__inner {
  border: 0;
}
::v-deep .el-cascader {
  line-height: 0.28rem;
}
.searchF {
  width: 2.2rem;
  display: flex;
  align-items: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding-right: 0.1rem;
}
.classifyChildItem.active {
  background: #f46600;
  border-radius: 0.2rem;
  color: #ffffff;
}

.classifyChildItem:hover {
  background: #f46600;
  border-radius: 0.2rem;
  color: #ffffff;
  transform: translateY(-2px);
}

.classifyChildItem {
  cursor: pointer;
  margin-right: 0.15rem;
  padding: 0.05rem 0.15rem;
  transition: 0.2s;
}

.classifyChild {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  font-size: 0.14rem;
  color: #333333;
}

.classifyItem {
  display: flex;
}

.classifyItemName {
  color: #040404;
  font-size: 0.14rem;
  font-weight: bold;
  white-space: nowrap;
  padding: 0.05rem 0;
}

.classifyCont {
  width: 100%;
  padding: 0 1.2rem;
}

.classifyBox {
  padding: 0 0.3rem 0.3rem;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 0.08rem;
  box-shadow: 0px 0px 0.2rem rgba(153, 153, 153, 0.15);
}
.fixedContent {
  width: 100%;
  min-width: 1400px;
  padding: 0 1.2rem;
}
.content {
  /* visibility: hidden; */
  opacity: 0;
  transition: opacity 0.5s;
  padding: 0 1.2rem;
}
.content.isFixe {
  visibility: visible;
  opacity: 1;
  /* margin-top: 0.4rem; */
}
.content.isFixe1 {
  /* visibility: visible; */
  opacity: 1;
  padding: 0.2rem 0;
  background: #ffffff;
  position: fixed;
  top: 0.68rem;
  width: 100%;
  z-index: 8;
}
.select {
  width: 1.45rem;
}

::v-deep .el-picker-panel {
  margin: 5px 1.2rem 5px 0 !important;
}

::v-deep .el-input {
  width: 100% !important;
  font-size: 0.14rem !important;
}

::v-deep .el-input__inner {
  height: 0.28rem;
  line-height: 0.28rem;
}

::v-deep .el-input__icon {
  line-height: 0.28rem;
}

.content:after {
  content: "";
  visibility: hidden;
  display: block;
  width: 0;
  height: 0;
  clear: both;
}

.select .el-input__inner.el-input__inner {
  background-color: #f7f6fb;
}

.tagName {
  margin-right: 0.2rem;
  line-height: 0.28rem;
}

.tagIcon {
  width: 0.16rem;
  height: 0.16rem;
  margin-right: 0.08rem;
}

.tagTile {
  color: #040404;
  font-size: 0.14rem;
}

.ContBox {
  width: 100%;
  min-width: 12rem;
	height: 1rem;
}

.tag {
  padding: 0.04rem 0.1rem;
  background: #fff;
  border-radius: 0.04rem;
  margin-right: 0.15rem;
  font-size: 0.14rem;
  color: #333333;
  cursor: pointer;
  transition: 0.15s;
}

.tag:hover {
  background: #f46600;
  color: #ffffff;
  transform: translateY(-2px);
}

.tag.active {
  background: #f46600;
  color: #ffffff;
}
</style>